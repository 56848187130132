import React from "react";
import {
  SectionWrapper,
  SocialMediaWrapper,
  IconsContainer,
  Icon,
  EmailWrapper,
  ContactWrapper,
  RowDivider,
  Email,
} from "./videoStyle";
import poster from "../../../assets/img/poster.jpg";
import trailer from "../../../assets/videos/trailer.mp4";
import { FOOTER_ICONS } from "../../shared/data";
//import { useRef } from "react";

const Video = ({ videoRef }) => {
  return (
    <>
      <SectionWrapper
        id='home'
        ref={videoRef}
        dangerouslySetInnerHTML={{
          __html: `
    <Mask></Mask>
      <video className="video" poster=${poster} autoPlay loop muted playsinline>
        <source src=${trailer} type="video/mp4" />
      </video>
  `,
        }}></SectionWrapper>
      <ContactWrapper>
        <RowDivider width='7%' />
        <EmailWrapper>
          <Email href='mailto:start@shiftcine.com'> start@shiftcine.com</Email>
        </EmailWrapper>
        <RowDivider width='20%' />

        <SocialMediaWrapper>
          <IconsContainer>
            {FOOTER_ICONS.map((icon, key) => (
              <Icon onClick={() => window.open(icon.link)} key={key}>
                <span>{icon.src}</span>
                <span style={{ marginLeft: "0.5rem" }}>{icon.name}</span>
              </Icon>
            ))}
          </IconsContainer>
        </SocialMediaWrapper>
        <RowDivider width='13%' />
      </ContactWrapper>
    </>
  );
};

export default Video;
