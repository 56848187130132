import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { clients } from "../data";
import SliderButtons from "../../shared/SliderButtons/SliderButtons";

import { SectionWrapper, Card } from "./clientsStyle";

const Clients = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 8,
      slidesToSlide: 1, // optional, default to 1.
    },
    bLaptop: {
      breakpoint: { max: 1440, min: 1280 },
      items: 8,
      slidesToSlide: 1, // optional, default to 1.
    },
    sLaptop: {
      breakpoint: { max: 1280, min: 820 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 820, min: 550 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 281 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    fold: {
      breakpoint: { max: 281, min: 0 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <SectionWrapper id='creators'>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        ssr={true}
        infinite={true}
        customTransition='transform 1000ms ease-in-out'
        arrows={false}
        renderButtonGroupOutside={true}
        shouldResetAutoplay={false}
        partialVisible={true}
        customButtonGroup={<SliderButtons title={"CLIENTS"} />}
        autoPlaySpeed={3000}
        autoPlay={true}>
        {clients.map((item, key) => (
          <Card
            key={key}
            isBig={item.isBig ? item.isBig : null}
            isSmall={item.isSmall ? item.isSmall : null}
            data-cursor-text='Drag'>
            <img src={item.image} alt={item.name} />
          </Card>
        ))}
      </Carousel>
    </SectionWrapper>
  );
};

export default Clients;
