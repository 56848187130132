import styled, { css } from "styled-components";
import { title } from "../../shared/sharedStyle";

import { ReactComponent as Forward } from "../../../assets/img/forward-1.svg";
import { ReactComponent as Backward } from "../../../assets/img/backward-1.svg";
import { ReactComponent as ForwardRed } from "../../../assets/img/forwardRed-1.svg";
import { ReactComponent as BackwardRed } from "../../../assets/img/backwardRed-1.svg";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;

  margin-bottom: 2rem;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
  }

  @media (max-width: 820px) {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 550px) {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h1`
  ${title}
  margin-bottom: 0;

  @media (max-width: 820px) {
    margin-bottom: 0;
  }

  @media (max-width: 550px) {
    margin-bottom: 0;
  }
`;

const arrowSize = css`
  width: 100%;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
    width: 75%;
  }

  @media (max-width: 950px) {
    width: 65%;
  }

  @media (max-width: 550px) {
    width: 50%;
  }

  @media (max-width: 280px) {
  }
`;

const IconContainer = css`
  display: flex;
  /* height: 100%; */
`;

const LeftContainer = styled.div`
  ${IconContainer}

  margin-left: 4rem;
`;
const RightContainer = styled.div`
  ${IconContainer}
  margin-left: 2rem;

  @media (max-width: 1280px) {
    margin-left: 0.5rem;
  }

  @media (max-width: 800px) {
    margin-left: 0;
  }

  @media (max-width: 505px) {
    margin-left: -1rem;
  }
`;

const RightIcon = styled(Forward)`
  ${arrowSize}
`;

const LeftIcon = styled(Backward)`
  ${arrowSize}
`;

const RightRedIcon = styled(ForwardRed)`
  ${arrowSize}
`;

const LeftRedIcon = styled(BackwardRed)`
  ${arrowSize}
`;

export {
  TitleContainer,
  Title,
  LeftContainer,
  RightContainer,
  RightIcon,
  LeftIcon,
  RightRedIcon,
  LeftRedIcon,
};
