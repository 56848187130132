import { css } from "styled-components";

const paddingWrapper = css`
  padding: 3rem 4rem;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
  }

  @media (max-width: 820px) {
    padding: 3rem 2rem;
  }

  @media (max-width: 550px) {
  }
`;

const title = css`
  color: white;
  font-size: 7rem;
  margin: 0;
  font-family: "Montserrat-ExtraBold";

  margin-bottom: 2rem;

  @media (max-width: 1440px) {
    font-size: 5rem;
  }

  @media (max-width: 1280px) {
    font-size: 4rem;
  }

  @media (max-width: 820px) {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 550px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 280px) {
  }
`;

export { paddingWrapper, title };
