import styled, { css } from "styled-components";
import { paddingWrapper } from "../../shared/sharedStyle";
import { ReactComponent as Contact } from "../../../assets/img/contact-us.svg";

const SectionWrapper = styled.section`
  ${paddingWrapper}
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex: 1;
  background-color: black;
  font-family: "Montserrat-Bold";

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  flex: 1;
  text-align: start;
  align-items: center;

  > h3 {
    color: white;
    font-size: 4rem;
    margin: 0;
    width: 80%;
  }

  @media (max-width: 1920px) {
    > h3 {
      font-size: 3rem;
    }
  }

  @media (max-width: 1536px) {
    > h3 {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 1280px) {
    > h3 {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 820px) {
    > h3 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 550px) {
    justify-content: center;
    text-align: center;

    margin-bottom: 2rem;
  }

  @media (max-width: 280px) {
    > h3 {
      font-size: 0.9rem;
    }
  }
`;

const contactInfo = css`
  color: red;
  font-size: 3.8rem;

  margin: 0;

  transition: all 0.5s;

  @media (max-width: 1920px) {
    font-size: 2.8rem;
  }

  @media (max-width: 1536px) {
    font-size: 2.3rem;
  }

  @media (max-width: 1280px) {
    font-size: 1.1rem;
  }

  @media (max-width: 820px) {
    font-size: 0.8rem;
  }

  @media (max-width: 280px) {
    font-size: 0.7rem;
  }
`;

const Email = styled.p`
  ${contactInfo}
`;

const Mobile = styled.p`
  ${contactInfo}
`;

const Details = styled.div`
  color: white;
  text-align: start;
  align-items: center;

  > h3 {
    font-size: 4rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  /* > p {
    color: red;
    font-size: 3.8rem;

    margin: 0;

    transition: all 0.5s;
  } */

  @media (max-width: 1920px) {
    > h3 {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 1536px) {
    > h3 {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 1280px) {
    > h3 {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 820px) {
    > h3 {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
  }

  @media (max-width: 550px) {
    > h3 {
    }
  }

  @media (max-width: 280px) {
    > h3 {
      font-size: 0.9rem;
    }
  }
`;

const DetailsContainer = styled.a`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;

  color: white;
  text-decoration: none;

  @media (max-width: 550px) {
    justify-content: center;
  }

  transition: all 0.5s;

  &:hover {
    ${Email} {
      transform: scale(1.1);
      transition: all 0.5s;
      text-decoration: underline;
    }
  }
`;

const StyledIcon = styled(Contact)`
  width: 20%;
  margin-right: 2rem;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
  }

  @media (max-width: 820px) {
    width: 15%;
    margin-right: 1rem;
  }

  @media (max-width: 550px) {
    height: 50%;
  }
`;

export {
  SectionWrapper,
  QuoteContainer,
  DetailsContainer,
  Details,
  StyledIcon,
  Email,
  Mobile,
};
