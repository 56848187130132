import React from "react";

import Footer from "../shared/Footer/Footer";
import OurWorks from "./OurWorks/OurWorks";
import OurTeam from "./OurTeam/OurTeam";
import Video from "./Video/Video";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Clients from "./Clients/Clients";

import { BrowserRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

const Home = ({ videoRef }) => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Shift Cinema</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>

      <div style={{ scrollBehavior: "smooth" }}>
        <Video videoRef={videoRef} />
        <About />
        <OurWorks />
        <OurTeam />
        <Clients />
        <Contact />

        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default Home;
