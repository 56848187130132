import styled from "styled-components";
import { paddingWrapper } from "../../shared/sharedStyle";

const SectionWrapper = styled.section`
  ${paddingWrapper}
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  text-align: start;
  background-color: black;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  margin: 0 0.5rem;
  width: calc(100%-1rem);
  aspect-ratio: 1/1.1;

  > img {
    width: calc(100% - 1rem);

    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      transform: scale(1.2);
      transition: all 0.5s;
    }
  }
`;

const LabelContainer = styled.div`
  position: absolute;
  width: calc(100% - 1rem);
  height: 25%;

  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  > h1 {
    width: calc(100% - 1rem);
    font-size: 2.5rem;
    margin: 0;
    font-family: "Montserrat-Bold";

    /* padding: 0 1.2rem; */
  }

  > h2 {
    width: calc(100% - 1rem);
    font-size: 2rem;
    margin: 0;
    margin-bottom: 0.6rem;
    font-family: "Montserrat-Regular";

    /* padding: 0 1.2rem; */
  }

  @media (max-width: 1920px) {
    > h1 {
      font-size: 1.75rem;
    }

    > h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 1536px) {
    > h1 {
      font-size: 1.5rem;
    }

    > h2 {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
  }

  @media (max-width: 1440px) {
    > h1 {
      font-size: 1.5rem;
    }

    > h2 {
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
  }

  /* @media (max-width: 1366px) {
    > h1 {
      font-size: 1.5rem;
    }

    > h2 {
      font-size: 1rem;
    }
  } */

  @media (max-width: 1280px) {
    > h1 {
      font-size: 1rem;
    }

    > h2 {
      font-size: 0.75rem;
      margin-bottom: 0.3rem;
    }
  }

  @media (max-width: 962px) {
    > h1 {
      font-size: 0.8rem;
    }

    > h2 {
      font-size: 0.6rem;
      margin-bottom: 0.3rem;
    }
  }

  @media (max-width: 601px) {
    > h1 {
      font-size: 0.6rem;
    }

    > h2 {
      font-size: 0.4rem;
      margin-bottom: 0.2rem;
    }
  }

  @media (max-width: 550px) {
    > h1 {
      font-size: 1rem;
    }

    > h2 {
      font-size: 0.75rem;
      margin-bottom: 0.3rem;
    }
  }

  @media (max-width: 280px) {
    > h1 {
      font-size: 0.8rem;
    }

    > h2 {
      font-size: 0.55rem;
      margin-bottom: 0.2rem;
    }
  }
`;

export { SectionWrapper, ImageContainer, LabelContainer };
