import styled from "styled-components";
import { paddingWrapper, title } from "../sharedStyle";

const SectionWrapper = styled.section`
  ${paddingWrapper}
  display: flex;
  flex-direction: row;
  background-color: black;
  color: white;
  font-family: "Montserrat-Bold";

  @media (max-width: 1280px) {
    flex-direction: column-reverse;
  }
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;

  > p {
    opacity: 0.8;
    font-size: 1.2rem;
    margin: 0;
  }

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: center;

    > p {
      font-size: 1rem;
    }
  }

  @media (max-width: 820px) {
    > p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 550px) {
  }

  @media (max-width: 280px) {
  }
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1280px) {
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

const IconsContainer = styled.div`
  width: 27.5%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: center;
  }
`;

const Icon = styled.div`
  margin-right: 1rem;

  @media (max-width: 1280px) {
    margin: 0;
    margin-right: 1rem;
  }
`;

const LogoContainer = styled.div`
  width: 27.5%;

  > img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    width: 20%;
  }

  @media (max-width: 550px) {
    width: 45%;
  }
`;

export {
  SectionWrapper,
  LeftContainer,
  RightContainer,
  IconsContainer,
  LogoContainer,
  Icon,
};
