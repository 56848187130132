import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { team } from "../data";
import SliderButtons from "../../shared/SliderButtons/SliderButtons";
import { SectionWrapper, ImageContainer, LabelContainer } from "./ourteamStyle";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 4,
    slidesToSlide: 1,
  },
  bLaptop: {
    breakpoint: { max: 1440, min: 1280 },
    items: 4,
    slidesToSlide: 1,
  },
  sLaptop: {
    breakpoint: { max: 1280, min: 820 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 820, min: 550 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 550, min: 281 },
    items: 1.5,
    slidesToSlide: 1,
  },
  fold: {
    breakpoint: { max: 281, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const OurTeam = () => {
  return (
    <SectionWrapper id='teams'>
      <Carousel
        responsive={responsive}
        swipeable
        draggable
        ssr
        infinite
        customTransition='transform 1000ms ease-in-out'
        arrows={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        partialVisible
        customButtonGroup={<SliderButtons title='OUR TEAM' />}>
        {team.map((item, key) => (
          <ImageContainer key={key} data-cursor-text='Drag'>
            <img src={item.image} alt='team members' />
            <LabelContainer>
              <h2>{item.name}</h2>
              <h1>{item.role}</h1>
            </LabelContainer>
          </ImageContainer>
        ))}
      </Carousel>
    </SectionWrapper>
  );
};

export default OurTeam;
