import styled, { css } from "styled-components";
import { paddingWrapper, title } from "../../shared/sharedStyle";
import { ReactComponent as SeeAllSvg } from "../../../assets/img/seeAll.svg";
import { ReactComponent as SeeAllRedSvg } from "../../../assets/img/seeAllRed.svg";

import ExpandMore from "@material-ui/icons/ExpandMore";

const SectionWrapper = styled.section`
  ${paddingWrapper}
  align-items: flex-start;
  background-color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.h1`
  ${title}
  align-self: center;
  @media (max-width: 550px) {
    align-self: flex-start;
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;

  margin-bottom: ${props => (props.isLast ? 0 : "7rem")};

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
    margin-bottom: ${props => (props.isLast ? 0 : "2rem")};
  }

  @media (max-width: 950px) {
    /* margin-bottom: 2rem; */
  }

  @media (max-width: 550px) {
    flex-direction: column;
    margin-bottom: ${props => (props.isLast ? 0 : "5rem")};
  }

  @media (max-width: 280px) {
  }
`;

const ImageContainer = styled.div`
  width: 47.5%;
  display: flex;

  align-items: flex-start;
  transition: all 0.5s;

  > img,
  video {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
    transition: all 0.5s;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Description = styled.div`
  width: 47.5%;

  > h1 {
    color: white;
    font-size: 5rem;
    text-align: left;
    margin: 0;
    font-family: "Montserrat-SemiBold";

    margin-bottom: 2.5rem;
  }

  > h2 {
    color: white;
    font-size: 2.5rem;
    text-align: left;
    margin: 0;
    font-family: "Montserrat-Regular";

    margin-bottom: 5.5rem;
  }

  > p {
    color: white;
    font-size: 1rem;
    text-align: left;
    margin: 0;
    font-family: "Montserrat-Regular";

    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    /* white-space: nowrap; */
    overflow: hidden;
  }

  @media (max-width: 2100px) {
    > h1 {
      font-size: 3.25rem;
      margin-bottom: 1.65rem;
    }
    > h2 {
      font-size: 1.65rem;
      margin-bottom: 3.75rem;
    }
    > p {
      font-size: 1rem;
      line-height: 1.875rem;
    }
  }

  @media (max-width: 1440px) {
    > h1 {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
    > h2 {
      font-size: 1.5rem;
      margin-bottom: 3.5rem;
    }
    > p {
      font-size: 1rem;
      line-height: 1.875rem;
      /* margin-top: 2rem; */
    }
  }

  @media (max-width: 1280px) {
    > h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    > h2 {
      font-size: 1rem;
      margin-bottom: 2.5rem;
    }
    > p {
      font-size: 0.7rem;
    }
  }

  /* @media (max-width: 950px) {
    > h1 {
      font-size: 1.75rem;
    }
    > h2 {
      font-size: 1.35rem;
    }
    > p {
      font-size: 0.9rem;
    }
  } */

  @media (max-width: 800px) {
    > h1 {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }
    > h2 {
      font-size: 0.75rem;
      margin-bottom: 2rem;
    }
    > p {
      font-size: 0.45rem;
    }
  }

  @media (max-width: 550px) {
    width: 100%;
    margin-top: 1rem;

    > h1 {
      font-size: 1.5rem;
    }
    > h2 {
      font-size: 1rem;
    }
    > p {
      font-size: 0.8rem;
    }
  }
`;

const SeeButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > p {
    color: white;
    margin-right: 1rem;
    font-size: 2.5rem;
  }

  @media (max-width: 1440px) {
    > p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1280px) {
    > p {
      font-size: 1rem;
    }
  }

  @media (max-width: 950px) {
    > p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 550px) {
    > p {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 280px) {
    > p {
      font-size: 0.8rem;
    }
  }
`;

const LoadButton = styled.div`
  border: 1px solid red;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  > p {
    color: white;
    margin-right: 1rem;
  }
`;

const ExpandIcon = styled(ExpandMore)`
  color: white;
  font-size: 20;
`;

const arrowSize = css`
  width: 100%;

  @media (max-width: 1440px) {
  }

  @media (max-width: 1280px) {
    width: 75%;
  }

  @media (max-width: 950px) {
    width: 65%;
  }

  @media (max-width: 550px) {
    width: 50%;
  }

  @media (max-width: 280px) {
  }
`;

const ArrowIcon = styled(SeeAllSvg)`
  ${arrowSize}
`;

const ArrowIconRed = styled(SeeAllRedSvg)`
  ${arrowSize}
`;

export {
  SectionWrapper,
  Title,
  Card,
  ImageContainer,
  Description,
  SeeButton,
  LoadButton,
  ExpandIcon,
  ArrowIcon,
  ArrowIconRed,
};
