import React from "react";
import { Title } from "../../home/Clients/clientsStyle";
import {
  SectionWrapper,
  LeftContainer,
  RightContainer,
  IconsContainer,
  LogoContainer,
  Icon,
} from "./footerStyle";
import { FOOTER_ICONS } from "../data";
import logo from "../../../assets/img/shift_cinema_logo.png";

const Footer = () => {
  return (
    <SectionWrapper>
      <LeftContainer>
        <p>© 2022 Shift Media. All rights reserved.</p>
      </LeftContainer>
      <RightContainer>
        <IconsContainer>
          {FOOTER_ICONS.map((icon, key) => (
            <Icon onClick={() => window.open(icon.link)} key={key}>
              {icon.src}
            </Icon>
          ))}
        </IconsContainer>
        <LogoContainer onClick={(event) => (window.location.href = "/")}>
          <img src={logo} alt="logo" />
        </LogoContainer>
      </RightContainer>
    </SectionWrapper>
  );
};

export default Footer;
