import { ReactComponent as Youtube } from "../../assets/img/youtube.svg";
//import { ReactComponent as LinkedIn } from "../../assets/img/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/img/instagram.svg";
//import { ReactComponent as Facebook } from "../../assets/img/facebook.svg";
export const HEADER_LINKS = [
  { title: "HOME", url: "/", y: "0", url2: "#home" },
  { title: "ABOUT", url: "/about", y: "600", url2: "#about" },
  { title: "SERVICES", url: "/services", y: "1000", url2: "#services" },
  // { title: "CREATORS", url: "/creators", y: "4800", url2: "#creators" },
  { title: "TEAMS", url: "/teams", y: "4000", url2: "#teams" },
  { title: "CONTACT US", url: "/contact-us", y: "5700", url2: "#contact-us" },
];

export const FOOTER_ICONS = [
  {
    src: <Youtube style={{ color: "#fff", width: 40 }} />,
    link: "https://youtube.com/channel/UCjYVWfZEIrGjY2gEGjj5U3A",
    alt: "youtube",
    name: "Youtube",
  },
  // {
  //   src: <Facebook style={{ color: "#fff", width: 40 }} />,
  //   link: "https://instagram.com/driveandtrack",
  //   alt: "facebook",
  // },
  {
    src: <Instagram style={{ color: "#fff", width: 40 }} />,
    link: "https://www.instagram.com/shiftcine/",
    alt: "home",
    name: "Instagram",
  },
  // {
  //   src: <LinkedIn style={{ color: "#fff", width: 40 }} />,
  //   link: "https://instagram.com/driveandtrack",
  //   alt: "linkedin",
  // },
];
