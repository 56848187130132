// import works_data0 from "../../assets/img/works-0.webp";
// import works_data1 from "../../assets/img/works-1.webp";
// import works_data2 from "../../assets/img/works-2.webp";
// import works_data3 from "../../assets/img/works-3.webp";

//mport works_data5 from "../../assets/img/works-5.webp";
import works_data6 from "../../assets/img/photography.jpg";
import works_data5 from "../../assets/img/social.jpg";
//import works_data6 from "../../assets/img/works-6.webp";

import works_vid1 from "../../assets/videos/works-01-lexus.mp4";
import works_vid2 from "../../assets/videos/works-02-mercedes.mp4";
import works_vid3 from "../../assets/videos/works-03-lx570.mp4";

import works_img1 from "../../assets/img/works-01-lexus.jpg";
import works_img2 from "../../assets/img/works-02-mercedes.jpg";
import works_img3 from "../../assets/img/works-03-lx570.jpg";

import person1 from "../../assets/img/person1.webp";
import person2 from "../../assets/img/person2.webp";
import person3 from "../../assets/img/person3.webp";
import person4 from "../../assets/img/person4.webp";
import person5 from "../../assets/img/person5.webp";

//clients
import Maserati from "../../assets/img/Maserati.png";
import Mini from "../../assets/img/MINI.png";
import Ferrari from "../../assets/img/ferrari-logo.png";
import Lexus from "../../assets/img/Lexus.png";
import RedBull from "../../assets/img/RedBull.png";
import BMW from "../../assets/img/BMW.png";

import AlfaRomeo from "../../assets/img/Alfa-Romeo.png";
import Hyundai from "../../assets/img/Hyundai.png";
import Mercedes from "../../assets/img/Mercedes.png";
import Porsche from "../../assets/img/Porsche.png";
import Chevrolet from "../../assets/img/chevrolet.png";
export const ourWorks = {
  // title:
  //   "A MULTI-MEDIA PRODUCTION AND CONTENT COMPANY. WE HAVE CREATED CONTENT, COMMERCIALS AND AD VIDEOS FOR A WIDE RANGE OF CLIENTS GLOBALLY ",
  title:
    "Cinematic production for the Automotive and motorsport industry is a very challenging field. It comes with rules and guidelines, and thanks to our experience, it gives us the upper hand in understanding how we can create what brands need & go above and beyond. And because we have a love for making something different, we’ve expanded our market beyond the Automotive industry to create something that speaks SHIFT.",
  ourWorks: "OUR WORKS",
  features: [
    {
      id: 0,
      image: works_img2,
      video: works_vid2,
      title: "TV Commercials",
      subtitle: "Automotive & Beyond",
      text: "Every brand we deal with has a specific guideline, but that doesn’t stop the love of creating something different, new & attractive. If our clients have an idea, we love doing our best to bring it to life. However, we love coming up with crazy ideas as well that ensure the satisfaction of our clients and help them stand out from the competition.",
    },
    {
      id: 2,
      image: works_img1,
      video: works_vid1,
      title: "Coverage with a twist",
      subtitle: "Automotive & Beyond",
      text: "When it comes to event coverage, we only do it for the sake of adding a twist from making sure every important moment is on camera to adding a creative direction to the base story of the event timeline to get the attention of the viewer till the last second, this is done with proper preparation with the brands and based on our years of experience in this field.",
    },
    {
      id: 3,
      image: works_img3,
      video: works_vid3,
      title: "Products Showcase and Presentation",
      subtitle: "Automotive & Beyond",
      text: "When a new Product comes to our clients, we seek creative ways to follow their production concept, adhere to their guidelines, and help them promote the product. No stone is left unturned, we make sure to cover every detail, and we go beyond to implement our unique production mentality. We are here to cater to your needs. If your idea requires unique locations, an experienced presenter, or an out-of-this-world concept, we’ve got you covered.",
      seeAll: "SEE ALL WORKS",
    },
    {
      id: 4,
      image: works_data5,
      title: "Social Content Creation & Accounts Management",
      subtitle: "Automotive",
      text: "From  something as simple as posting, to something as detailed as creating local content for different social media platforms (Facebook, Instagram, TikTok, Snapchat, Youtube, etc.), we’ve got your back with more than 8 years of experience working with renowned brands.",
    },
    {
      id: 5,
      image: works_data6,
      title: "Photography",
      subtitle: "Automotive & Beyond",
      text: "“Point and shoot” is one thing, but preparing for a photo session for us is beyond that. Our preparation goes beyond just the equipment. After 10 years of experience, we’ve learned that thorough pre-production and client meetings help us make sure that we have everything covered on the day of the shoot and that our clients are satisfied with the final result.",
    },
  ],
};

export const clients = [
  {
    id: 0,
    image: Maserati,
    name: "Maserati",
  },
  {
    id: 1,
    image: Mini,
    name: "Mini",
  },
  {
    id: 2,
    image: Ferrari,
    name: "Ferrari",
    isBig: true,
  },
  {
    id: 3,
    image: Lexus,
    name: "Lexus",
  },
  {
    id: 4,
    image: RedBull,
    name: "RedBull",
  },
  {
    id: 5,
    image: BMW,
    name: "BMW",
    isBig: true,
  },
  {
    id: 6,
    image: AlfaRomeo,
    name: "AlfaRomeo",
    isSmall: true,
  },
  {
    id: 7,
    image: Hyundai,
    name: "Hyundai",
  },
  {
    id: 8,
    image: Mercedes,
    name: "Mercedes",
  },
  {
    id: 9,
    image: Porsche,
    name: "Porsche",
  },
  {
    id: 9,
    image: Chevrolet,
    name: "Chevrolet",
  },
];

export const team = [
  {
    id: 0,
    image: person3,
    name: "Diaa Ali Amer",
    role: "Head of Production / Founder",
  },
  {
    id: 1,
    image: person2,
    name: "Amr El Masry",
    role: "Production / Co-Founder",
  },
  {
    id: 2,
    image: person5,
    name: "Yazan Al Shafi",
    role: "Camera operator",
  },
  {
    id: 3,
    image: person1,
    name: "Abdallah Naser",
    role: "Camera operator",
  },
  {
    id: 4,
    image: person4,
    name: "Mahmoud Mahfouz",
    role: "Production Assistant",
  },
];
