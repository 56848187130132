import React from "react";
import {
  SectionWrapper,
  QuoteContainer,
  DetailsContainer,
  Details,
  StyledIcon,
  Email,
  Mobile,
} from "./contactStyle";

const About = () => {
  return (
    <SectionWrapper id='contact-us'>
      <QuoteContainer>
        <h3>
          Check out our latest work and drop by our studio for a coffee at
          #Drivehub Automotive
        </h3>
      </QuoteContainer>

      <DetailsContainer href='mailto:start@shiftcine.com'>
        <StyledIcon />
        <Details>
          <h3>CONTACT US</h3>
          <Email>start@shiftcine.com</Email>
          <Mobile>+974 3336 8580</Mobile>
        </Details>
      </DetailsContainer>
    </SectionWrapper>
  );
};

export default About;
