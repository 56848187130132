import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { HEADER_LINKS } from "./data";
import Scroll from "react-scroll";

import logo from "../../assets/img/shift_cinema_logo.png";
import pdf from "../../assets/document/brochure2022.pdf";
import Lottie from "../shared/lottie";
import download from "../../assets/Saving.json";
function Header({ videoRef }) {
  const ScrollLink = Scroll.ScrollLink;
  //passing useState for language from another component ( LanguageContext ) using useContext
  const { innerWidth: width } = window;
  const [click, setClick] = useState(false);
  const [isSticky, setisSticky] = useState(false);
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const showSticky = window.scrollY > 70;
      const inVideoSection = window.scrollY > videoRef.current.scrollHeight;

      if (showSticky) {
        setisSticky(true);
      } else {
        setisSticky(false);
      }

      if (inVideoSection) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [videoRef]);
  const handleClick = () => setClick(!click);

  return (
    <Nav width={width} isSticky={isSticky} showBackground={showBackground}>
      {/* <Blur click={click} /> */}
      <Logos href={"/"} onClick={event => (window.location.href = "/")}>
        <img src={logo} alt='shift-cinema-logo'></img>
      </Logos>

      <MenuItemsWrapper>
        <MenuItems>
          <div className='right'>
            {HEADER_LINKS.slice(0, 7).map((item, key) => (
              <NavLink
                isSticky={isSticky}
                key={key}
                // onClick={() =>
                //   window.scrollTo({ top: item.y, left: 0, behavior: "smooth" })
                // }
                href={item.url2}
                exact={true}>
                <div>{item.title}</div>
              </NavLink>
            ))}
            <ScrollLink
              to='example-destination'
              spy={true}
              smooth={true}
              duration={500}
              className='some-class'
              activeClass='some-active-class'>
              Link Text Goes Here
            </ScrollLink>
          </div>
        </MenuItems>
      </MenuItemsWrapper>
      <DownloadWrapper style={{ width: "25%" }}>
        <Download href={pdf} target='_blank'>
          <Lottie src={download} style={{ width: "10%" }} />
          <p>DOWNLOAD OUR BROUCHURE</p>
        </Download>
      </DownloadWrapper>
      <MenuIcon className='material-icons md-48' onClick={handleClick}>
        menu
      </MenuIcon>
      <SideNav click={click}>
        <ClearIcon className='material-icons' onClick={handleClick}>
          clear
        </ClearIcon>

        {HEADER_LINKS.map((item, key) => (
          <SideLink to={item.url} key={key} href={item.url2}>
            {item.title}
          </SideLink>
        ))}
        <SideLink href={pdf} download>
          DOWNLOAD BROUCHURE
        </SideLink>
      </SideNav>
    </Nav>
  );
}

export default Header;

const Nav = styled.header`
  height: 5rem;
  width: ${prop => (prop.width ? prop.width : "100%")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  //position: sticky;
  position: ${prop => (prop.isSticky ? "sticky" : "absolute")};
  background-color: ${prop => (prop.showBackground ? "#000" : "rgba(0,0,0,0)")};

  top: 0;
  right: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 800px) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: ${prop => (prop.width ? prop.width : "100%")};
  }
`;
const Logos = styled.a`
  font-size: 2rem;
  padding: 0.7rem 0rem 0rem 5%;
  width: "25%";
  @media (max-width: 1024px) {
    padding: 10px 40px 10px 20px;
  }

  @media (max-width: 800px) {
    padding: 20px 20px 10px 20px;
  }

  @media (max-width: 480px) {
    padding: 20px 20px 10px 30px;
  }

  @media (max-width: 300px) {
    padding: 20px 20px 10px 20px;
  }

  > img {
    width: 150px;
    height: 65px;

    @media (max-width: 480px) {
      width: 70px;
      height: 30px;
    }
  }
`;

// const Blur = styled.div`
//   display: ${prop => (prop.click ? "" : "none")};
//   position: fixed;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   background-color: black;
//   opacity: 0.5;
//   z-index: 2;
// `;
const MenuItemsWrapper = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    display: none;
  }
`;
const MenuItems = styled.div`
  flex: 0.83 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

const MenuIcon = styled.span`
  position: absolute;
  left: ${prop => (prop.isRTL ? "6%" : "")};
  right: ${prop => (prop.isRTL ? "" : "6%")};
  display: none;
  color: #ffff;

  @media (max-width: 1024px) {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
`;
const DownloadWrapper = styled.div`
  width: 25 %;
  @media (max-width: 1024px) {
    display: none;
  }
  &:hover {
    transform: scale(1.1);
    transition: all 0.5s;
  }
`;

const ClearIcon = styled.span`
  position: absolute;
  top: 1%;
  left: ${prop => (prop.isRTL ? "10%" : "80%")};
  color: #000;

  @media (max-width: 680px) {
    top: 2%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const activeClassName = "nav-item-active";

const NavLink = styled.a`
  &:active {
    color: var(--dana-color);
  }
  font-family: "Montserrat-Bold";
  font-size: 1.125rem;
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  color: #ffff;
  padding: ${props => (props.isSticky ? "0rem 0.5rem 0rem" : "0rem 2rem 0rem")};

  text-decoration: none;
  transform: ${props => (props.isSticky ? "scale(0.8)" : "scale(1)")};
  transition: all 0.7s;
  @media (max-width: 1600px) {
    font-size: 1rem;
  }

  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }
  @media screen (min-width: 1280px) {
    font-size: 0.8rem;
  }
`;
const Download = styled.a`
  display: flex;

  align-items: center;
  &:active {
    color: var(--dana-color);
  }
  font-family: "Montserrat-Bold";
  font-size: 1.125rem;
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  color: #ffff;
  padding: ${props => (props.isSticky ? "0rem 0.5rem 0rem" : "0rem 2rem 0rem")};
  float: ${props => (props.align === "center" ? "right" : "left")};
  text-decoration: none;
  transform: ${props => (props.isSticky ? "scale(0.8)" : "scale(1)")};
  transition: all 0.7s;
  @media (max-width: 1600px) {
    font-size: 1rem;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }
`;

const SideNav = styled.div`
  font-family: "Montserrat-Medium";
  height: 100%;
  width: ${prop => (prop.click ? "250px" : "0px")};
  position: fixed;
  z-index: 2;
  top: 0;
  right: ${prop => (prop.isRTL ? "" : "0")};
  left: ${prop => (prop.isRTL ? "0" : "")};
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  display: flex;
  flex-flow: column;
  transition: ${prop => (prop.prevRTL !== prop.isRTL ? "0" : "0.5s")};
  padding-top: 60px;
  /* display: none; */
`;

// const SideLink = styled(Link).attrs({ activeClassName })`
const SideLink = styled.a`
  /* &.${activeClassName} {
    opacity: 0.6;
  } */

  padding: ${prop =>
    prop.language === "ar" ? "8px 32px 10px 0px" : "8px 0px 10px 32px"};
  text-decoration: none;
  font-size: 1.5rem;

  color: #000;
  white-space: nowrap;
  transition: 0.3s;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 680px) {
    font-size: 0.9rem;
  }

  @media (max-width: 880px) {
    font-size: 0.7rem;
  }

  &:hover {
    color: #f1f1f1;
  }
`;
