import React, { useState, useEffect } from "react";
import {
  TitleContainer,
  Title,
  LeftContainer,
  RightContainer,
  RightIcon,
  LeftIcon,
  RightRedIcon,
  LeftRedIcon,
} from "./sliderButtonsStyle";

const SliderButtons = ({ next, previous, goToSlide, carouselState, title }) => {
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [isRightHovered, setIsRightHovered] = useState(false);

  const { totalItems, currentSlide } = carouselState;
  return (
    <TitleContainer className="custom-button-group">
      <Title>{title}</Title>
      <LeftContainer
        onMouseEnter={() => setIsLeftHovered(true)}
        onMouseLeave={() => setIsLeftHovered(false)}
        onClick={() => previous()}
      >
        {isLeftHovered ? <LeftRedIcon /> : <LeftIcon />}
        {/* <LeftIcon /> */}
      </LeftContainer>
      <RightContainer
        onMouseEnter={() => setIsRightHovered(true)}
        onMouseLeave={() => setIsRightHovered(false)}
        onClick={() => next()}
      >
        {isRightHovered ? <RightRedIcon /> : <RightIcon />}
        {/* <RightIcon /> */}
      </RightContainer>
    </TitleContainer>
  );
};
export default SliderButtons;
