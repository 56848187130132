import styled from "styled-components";
import { paddingWrapper } from "../../shared/sharedStyle";

const SectionWrapper = styled.section`
  flex: 1;
  background-color: black;
  font-family: "Montserrat-Regular";

  ${paddingWrapper}

  > p {
    color: white;
    font-size: 2rem;
    line-height: 3.625rem;
    margin: 0;
  }

  @media (max-width: 1440px) {
    > p {
      font-size: 2rem;
    }
  }

  @media (max-width: 1280px) {
    > p {
      font-size: 2rem;
    }
  }

  @media (max-width: 820px) {
    > p {
    }
  }

  @media (max-width: 550px) {
    > p {
      font-size: 1rem;
      text-align: left;
      line-height: 2.625rem;
    }
  }

  @media (max-width: 280px) {
    > p {
      font-size: 1rem;
      text-align: left;
    }
  }
`;
const DownloadWrapper = styled.div`
  display: none;
  @media (max-width: 550px) {
    width: 100 %;
    display: block;
  }
`;
const Download = styled.a`
  &:active {
    color: var(--dana-color);
  }
  font-family: "Montserrat-Bold";

  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  color: #ffff;

  text-decoration: none;

  transition: all 0.7s;
  @media (max-width: 1600px) {
    font-size: 1rem;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }
  @media (max-width: 550px) {
    display: flex;
    width: 100 %;
    justify-content: flex-start;
    align-items: center;
  }
  @media (max-width: 550px) {
    > p {
      font-size: 0.8rem;
      text-align: left;
    }
  }
`;

export { SectionWrapper, DownloadWrapper, Download };
