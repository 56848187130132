import Header from "./modules/shared/Header";
import Home from "./modules/home/Home";
import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useRef } from "react";
import "./scss/index.scss";

import MouseFollower from "mouse-follower";
import gsap from "gsap";

MouseFollower.registerGSAP(gsap);
const cursor = new MouseFollower();

function App() {
  const videoRef = useRef(null);

  return (
    <div className="App">
      <Router>
        <Header videoRef={videoRef} />
        <Switch>
          <Route
            path="/"
            // component={Home}
            render={(props) => <Home {...props} videoRef={videoRef} />}
          />
          {/* <Route path='/about' component={AboutUs} />
            <Route path='/cookie-policy' component={CookiePolicy} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/factory' component={OurFactory} />
            <Route path='/careers' component={Career} />
            <Route path='/projects' component={OurProjects} />
            <Route path='/why-us' component={WhyUs} />
            <Route path='/products' component={OurProducts} /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
