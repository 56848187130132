import React from "react";
import { SectionWrapper, DownloadWrapper, Download } from "./aboutStyle";
import { ourWorks } from "../data";
import pdf from "../../../assets/document/brochure2022.pdf";
import Lottie from "../../shared/lottie";
import download from "../../../assets/Saving.json";

const About = () => {
  return (
    <SectionWrapper id='about'>
      <p>{ourWorks.title}</p>
      <DownloadWrapper>
        <Download href={pdf} target='_blank'>
          <p>DOWNLOAD OUR BROUCHURE</p>
          <Lottie src={download} style={{ width: "15%" }} />
        </Download>
      </DownloadWrapper>
    </SectionWrapper>
  );
};

export default About;
