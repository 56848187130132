import styled from "styled-components";
import { paddingWrapper, title } from "../../shared/sharedStyle";

const SectionWrapper = styled.section`
  ${paddingWrapper}

  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  background-color: black;
  text-align: left;

  .carousel-container {
    margin-bottom: 4rem;
  }
`;

const Title = styled.h1`
  ${title}
`;

const Card = styled.div`
  width: calc(100%-1rem);
  aspect-ratio: 1/1;
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 5px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 100%;
    transform: ${props =>
      props.isBig ? "scale(0.75)" : props.isSmall ? "scale(1.25)" : ""};
  }

  &:hover {
    transform: scale(1.06);
    transition: all 0.5s;
  }

  @media (max-width: 1440px) {
    margin: 0.3rem;
  }

  @media (max-width: 1280px) {
    margin: 0.25rem;
  }

  @media (max-width: 820px) {
  }

  @media (max-width: 550px) {
    margin: 0.2rem;
  }

  @media (max-width: 280px) {
  }
`;

export { SectionWrapper, Title, Card };
