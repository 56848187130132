import React from "react";
import { ourWorks } from "../data";

import {
  SectionWrapper,
  Title,
  Card,
  ImageContainer,
  Description,
} from "./ourworksStyle";

const OurWorks = () => {
  return (
    <SectionWrapper id='services'>
      <Title>OUR SERVICES</Title>

      {/* --------CARD--------- */}
      {ourWorks.features.map((feature, i, features) => (
        <Card
          key={i}
          direction={i % 2 === 0 ? "row" : "row-reverse"}
          isLast={i === features.length - 1}>
          {/* --------IMAGE--------- */}

          {/* --------DESCRIPTION--------- */}
          <Description isRow={i % 2 === 0}>
            <h1>{feature.title}</h1>
            <h2>{feature.subtitle}</h2>
            <p>{feature.text}</p>
          </Description>
          <ImageContainer
            isRow={i % 2 === 0}
            dangerouslySetInnerHTML={{
              __html: `
              <video poster=${feature.image} autoPlay loop muted playsinline  >
                <source src=${feature.video} type="video/mp4" />
              </video>
            `,
            }}></ImageContainer>
        </Card>
      ))}
    </SectionWrapper>
  );
};

export default OurWorks;
