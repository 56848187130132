import styled from "styled-components";

const SectionWrapper = styled.section`
  width: 100%;
  background-color: black;
  position: relative;

  > video {
    width: 100%;
  }
`;
const IconsContainer = styled.div`
  width: 27.5%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: center;
  }
`;
const Icon = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);

  @media (max-width: 1280px) {
    margin: 0;
    margin-right: 1rem;
  }
  &:hover {
    transform: scale(1.1);
    transition: all 0.5s;
  }
`;

const SocialIconWrapper = styled.div`
  width: 25%;
`;
const SocialMediaWrapper = styled.div`
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: end;
  align-items: center;
  left: 5%;
  right: 5%;
  color: #ffff;

  width: 25%;
`;
const RowDivider = styled.div`
  display: flex;
  align-content: center;
  align-self: center;

  align-items: center;

  width: ${prop => (prop.width ? prop.width : "100%")};
`;

const EmailWrapper = styled.div`
  display: flex;
  align-content: center;
  align-self: center;

  align-items: center;

  color: #ffff;

  width: 35%;
`;
const Email = styled.a`
  color: #ffff;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  &:hover {
    transform: scale(1.1);
    transition: all 0.5s;
    text-decoration: underline;
  }
`;
const ContactWrapper = styled.div`
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  position: absolute;

  right: 8%;
  left: 0;
  color: #ffff;
  bottom: 20px;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  /* opacity: 0; */
  /* border: 1px solid red; */

  /* ff 3.6+ */
  background: -moz-linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.91) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.91) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.91) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#000000', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.91) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    3deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.91) 100%
  );
`;

export {
  SectionWrapper,
  Mask,
  SocialMediaWrapper,
  SocialIconWrapper,
  IconsContainer,
  Icon,
  EmailWrapper,
  ContactWrapper,
  RowDivider,
  Email,
};
